@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap);
*{
    margin: 0;
    padding: 0;
}
html{
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
}

